<!-- 居民点单详情 -->
<template>
  <div class="">
    <el-dialog
      :z-index="10"
      :title="title"
      :visible.sync="visible"
      width="50%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <!-- 待接单 -->
      <div
        v-if="taskStatus === '1' || taskStatus === '2' || taskStatus === '0'"
        style="
          background-color: #edf7ff;
          border: 1px solid #a1bdff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 30px;
        "
      >
        <div
          v-if="taskStatus === '1'"
          style="margin-top: 6px; margin-bottom: 6px; color: #2a5cff"
        >
          待接单
        </div>
        <div
          v-if="taskStatus === '2'"
          style="margin-top: 6px; margin-bottom: 6px; color: #2a5cff"
        >
          进行中
        </div>
        <div
          v-if="taskStatus === '0'"
          style="margin-top: 6px; margin-bottom: 6px; color: #2a5cff"
        >
          审核中
        </div>
      </div>

      <!-- 已完成 -->
      <div
        v-if="taskStatus === '3' || taskStatus === '4'"
        style="
          background-color: #f5fdee;
          border: 1px solid #ddf4c8;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 30px;
        "
      >
        <div
          v-if="taskStatus === '3'"
          style="
            margin-top: 6px;
            margin-bottom: 6px;
            color: #67c23a;
            font-weight: bold;
            font-size: 0.08rem;
          "
        >
          已完成
        </div>
        <div
          v-if="taskStatus === '4'"
          style="
            margin-top: 6px;
            margin-bottom: 6px;
            color: #67c23a;
            font-weight: bold;
            font-size: 0.08rem;
          "
        >
          已评价
        </div>
      </div>
      <!-- 已取消 -->
      <div
        v-if="taskStatus === '5'"
        style="
          background-color: #e7e7e7;
          border: 1px solid #d9dde7;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 30px;
        "
      >
        <div
          v-if="taskStatus === '5'"
          style="
            margin-top: 6px;
            margin-bottom: 6px;
            color: #909399;
            font-weight: bold;
            font-size: 0.08rem;
          "
        >
          已取消
        </div>
      </div>
      <!-- 已拒绝 -->
      <div
        v-if="taskStatus === '6'"
        style="
          background-color: #fff0f0;
          border: 1px solid #fff0f0;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 30px;
          width: 100%;
        "
      >
        <div
          style="
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin-top: 6px;
            margin-bottom: 6px;
            color: #f95a5a;
            width: 100%;
            padding: 0% 2%;
          "
        >
          <div style="border-bottom: 1px solid #FFC0C0; width: 100%">
            <div
              style="
                display: flex;
                justify-content: center;
                font-weight: bold;
                font-size: 0.08rem;
              "
            >
              已拒绝
            </div>
          </div>

          <div style="display: flex; justify-content: center;font-weight: bold;">
            拒绝理由：
            <div style="font-weight: normal;">{{ recordInfo.refuseReason }}</div>
          </div>
        </div>
      </div>
      <!-- 接单基本信息 -->
      <a-descriptions
        layout="vertical"
        style="
          word-break: break-word;
          word-wrap: break-word;
          white-space: pre-wrap;
        "
      >
        <a-descriptions-item label="点单人">
          {{ recordInfo.residentName }}
        </a-descriptions-item>
        <a-descriptions-item label="性别">
          {{ recordInfo.gender }}
        </a-descriptions-item>
        <a-descriptions-item label="联系方式">
          {{ recordInfo.residentPhone }}
        </a-descriptions-item>
        <a-descriptions-item label="所属网格">
          {{ recordInfo.gridName }}
        </a-descriptions-item>
        <a-descriptions-item label="点单类型" :span="2">
          {{ recordInfo.taskTypeName }}
        </a-descriptions-item>

        <a-descriptions-item label="服务地点" :span="3">
          {{ recordInfo.taskLocation }}
        </a-descriptions-item>
        <a-descriptions-item label="需求内容" :span="3">
          {{ recordInfo.taskDescription }}
        </a-descriptions-item>
        <a-descriptions-item label="图片" :span="3">
          <imagePreview ref="img1" :businessId="businessId"></imagePreview>
        </a-descriptions-item>
      </a-descriptions>
      <!-- 接单详情 -->
      <div
        v-if="taskStatus === '2' || taskStatus === '4' || taskStatus === '3'"
        style="
          border-left: 4px solid #2a5cff;
          font-size: 0.08rem;
          font-weight: bold;
          color: black;
          line-height: 100%;
          margin: 15px 0;
        "
      >
        &nbsp;接单详情
      </div>
      <a-descriptions
        style="
          word-break: break-word;
          word-wrap: break-word;
          white-space: pre-wrap;
        "
        layout="vertical"
        v-if="taskStatus === '2' || taskStatus === '4' || taskStatus === '3'"
      >
        <a-descriptions-item label="接单人">
          {{ recordInfo.volunteerName }}
        </a-descriptions-item>
        <a-descriptions-item label="联系方式">
          {{ recordInfo.volunteerPhone }}
        </a-descriptions-item>
      </a-descriptions>
      <!-- 积分详情 -->
      <div
        v-if="taskStatus !== '0'"
        style="
          border-left: 4px solid #2a5cff;
          font-size: 0.08rem;
          font-weight: bold;
          color: black;
          line-height: 100%;
          margin: 15px 0;
        "
      >
        &nbsp;积分详情
      </div>
      <a-descriptions
        style="
          word-break: break-word;
          word-wrap: break-word;
          white-space: pre-wrap;
        "
        layout="vertical"
        v-if="taskStatus !== '0'"
      >
        <a-descriptions-item label="积分分值">
          {{ recordInfo.integralScore }}分
        </a-descriptions-item>
      </a-descriptions>
      <!-- 服务流程 -->
      <div
        v-if="
          taskStatus !== '0' &&
          taskStatus !== '5' &&
          taskStatus !== '6' &&
          taskStatus !== ''
        "
        style="
          border-left: 4px solid #2a5cff;
          font-size: 0.08rem;
          font-weight: bold;
          color: black;
          line-height: 100%;
          margin: 15px 0;
        "
      >
        &nbsp;服务流程
      </div>
      <el-steps
        v-if="
          taskStatus !== '0' &&
          taskStatus !== '5' &&
          taskStatus !== '6' &&
          taskStatus !== ''
        "
        :active="active"
      >
        <el-step title="待接单" icon="iconfont icon-liucheng-daijiedan">
        </el-step>
        <el-step
          title="进行中"
          icon="iconfont icon-liucheng-jinhangzhong"
        ></el-step>
        <el-step title="已完成" icon="iconfont icon-liucheng-yiwancheng">
        </el-step>
        <el-step title="已评价" icon="iconfont icon-liucheng-yipingjia">
        </el-step>
      </el-steps>
      <!-- 评价 -->
      <div class="rate" v-if="taskStatus === '4'">
        <div style="font-size: 0.09rem; font-weight: bold">评价</div>
        <a-rate :default-value="evaluate" allow-half disabled />
      </div>
      <span
        v-if="
          taskStatus !== '4' ||
          taskStatus !== '5' ||
          (taskStatus !== '3' && taskStatus !== '')
        "
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          @click="visible = false"
          v-if="taskStatus === '1' || taskStatus === '2'"
          >取 消</el-button
        >
        <el-button
          v-if="taskStatus === '1'"
          :loading="saveType"
          style="
            background-color: #2a5cff;
            color: white;
            border: 1px solid #2a5cff;
          "
          @click="chooseDispach"
          >派 单</el-button
        >
        <el-button
          v-if="taskStatus === '1'"
          @click="cancelOrder"
          :loading="saveType"
          style="
            background-color: #e6a23c;
            color: white;
            border: 1px solid #e6a23c;
          "
          >取消点单</el-button
        >
        <el-button
          v-if="taskStatus === '2'"
          @click="cancelDispatch"
          :loading="saveType"
          style="
            background-color: #e6a23c;
            color: white;
            border: 1px solid #e6a23c;
          "
          >取消派单</el-button
        >
        <el-button
          v-if="taskStatus === '0'"
          @click="pass"
          style="
            background-color: #67c23a;
            color: white;
            border: 1px solid #67c23a;
          "
          >通 过</el-button
        >
        <el-button
          v-if="taskStatus === '0'"
          @click="refuse"
          style="
            background-color: #f95a5a;
            color: white;
            border: 1px solid #f95a5a;
          "
          >拒 绝</el-button
        >
      </span>
    </el-dialog>
    <!-- 派单弹窗 -->
    <choose-people
      ref="choosePeople"
      @choose="receiveDispatchId"
    ></choose-people>
    <!-- 通过弹窗 -->
    <el-dialog
      :z-index="10"
      title="设置积分"
      :visible.sync="visible1"
      width="30%"
      @close="closeModal1"
      :close-on-click-modal="false"
    >
      <el-input-number
        v-model="integralScore"
        placeholder="请输入..."
        controls-position="right"
        :min="0"
        :max="99999"
      ></el-input-number>
      <!-- <el-input v-model="integralScore" placeholder="请输入..." maxlength="200"></el-input> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModal1">取 消</el-button>
        <el-button
          style="
            background-color: #67c23a;
            color: white;
            border: 1px solid #67c23a;
          "
          @click="setScore"
          :loading="saveType"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 拒绝弹窗 -->
    <el-dialog
      :z-index="10"
      title="拒绝理由"
      :visible.sync="visible2"
      width="30%"
      @close="closeModal2"
      :close-on-click-modal="false"
    >
      <el-input
        v-model="refuseReason"
        placeholder="请输入..."
        maxlength="20"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModal2">取 消</el-button>
        <el-button
          style="
            background-color: #67c23a;
            color: white;
            border: 1px solid #67c23a;
          "
          @click="setRefusalReason"
          :loading="saveType"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
const formList = [
  {
    label: "姓名",
    type: "",
    model: "name",
    options: { allowClear: true, placeholder: "请输入" },
    col: { span: 12 },
    rules: [{ required: true, message: "请输入活动名称" }],
  },
  {
    label: "个人照片",
    type: "",
    model: "file",
    options: { allowClear: true, placeholder: "请输入" },
    col: { span: 12 },
    rules: [{ required: true, message: "请输入活动名称" }],
  },
  {
    label: "身份证号",
    type: "",
    model: "identityCard",
    options: { placeholder: "请选择人员", disabled: true },
    col: { span: 12 },
    rules: [
      {
        required: true,
        message: "身份证号不能为空",
      },
    ],
  },
  {
    label: "性别",
    type: "",
    model: "auditStatus",
    options: { placeholder: "请选择人员", disabled: true },
    col: { span: 12 },
    rules: [
      {
        required: true,
        message: "性别不能为空",
      },
    ],
  },
  {
    label: "年龄",
    type: "",
    model: "residentId",
    options: { placeholder: "请选择人员", disabled: true },
    col: { span: 12 },
    rules: [{ required: true, message: "年龄不能为空" }],
  },
  {
    label: "联系电话", //自动获取可修改
    type: "",
    model: "contactWay",
    options: {
      placeholder: "请输入联系电话",
    },
    col: { span: 12 },
    rules: [
      {
        required: false,
        pattern: /^1[3-9]\d{9}$/,
        message: "请输入正确格式的联系电话",
      },
    ],
  },
  {
    label: "所属网格", //自动获取
    type: "",
    model: "gridName",
    options: {
      placeholder: "所属网格",
      disabled: true,
    },
    col: { span: 12 },
    rules: [{ required: true, message: "请选择所属网格" }],
  },
  {
    label: "服务地点",
    type: "input",
    model: "activityLocation",
    options: {
      placeholder: "服务地点",
      maxLength: 50,
    },
    col: { span: 24 },
    rules: [{ required: true, message: "请输入服务地点" }],
  },
  {
    label: "点单类型",
    type: "select",
    model: "type",
    options: { placeholder: "请选择点单类型" },
    col: { span: 24 },
    rules: [{ required: true, message: "请选择点单类型" }],
  },
  {
    label: "需求内容",
    type: "textarea",
    model: "activityContent",
    options: { placeholder: "活动内容", minRows: 3, maxLength: 200 },
    col: { span: 24 },
    rules: [{ required: true, message: "需求内容不能为空" }],
  },
  {
    label: "图片",
    type: "",
    model: "fileIds",
    options: {},
    col: { span: 24 },
    rules: [{ required: true, message: "请上传图片" }],
  },
];
import api from "./api";
import imageUpload from "./ax-image-modal copy/src/image-upload.vue";
import imagePreview from "./image-preview/imagePreview";
import choosePeople from "./choosePeople.vue";
export default {
  components: { imagePreview, imageUpload, choosePeople },
  data() {
    return {
      api,
      visible: false,
      visible1: false,
      visible2: false,
      integralScore: "", //审核通过设置积分
      refuseReason: "", //审核不通过填写拒绝理由
      businessId: "",
      id: "",
      gridId: "", //网格id
      title: "审核中", //待接单、进行中、已评价、已完成、审核中、已取消,
      taskStatus: "", //点单状态
      evaluate: "",
      active: 0,
      selectLable: "",
      noChoice: false,
      disabled: false,
      fileIds: [], //图片数组
      saveType: false,
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 150 },
        { col: { span: 8 } }
      ),
      selectedPeopleList: [],
      nameId: "", //姓名id
      name: "", //姓名
      recordInfo: {}, //点单详情数据
    };
  },
  watch: {
    businessId(newval) {
      this.businessId = newval;
    },
  },
  computed: {},
  mounted() {},
  methods: {
    // 选择派单人员
    chooseDispach() {
      this.$refs.choosePeople.openModal({ title: "网格员派单" });
    },
    // 接收子组件传过来的dispatchId
    async receiveDispatchId(item) {
      console.log(item, "185");
      if (item.id) {
        const obj = {
          id: this.id,
          volunteerId: item.id,
          volunteerPhone: item.contactWay,
          taskStatus: "2",
        };
        try {
          const res = await api.auditRecord(obj);
          console.log(res);
          if (res.status === 200) {
            this.$message.success("派单成功");
            this.closeModal();
          } else {
            this.$message.error("派单失败");
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
    // 打开弹窗
    openModal(record = {}) {
      this.visible = true;
      this.recordInfo = {};
      this.saveType = false;
      this.businessId = record.id;
      this.id = record.id;
      this.$nextTick(() => {
        this.$refs.img1.getPicList(record.id);
      });
      this.title = record.title;
      this.getRecordById(record.id);
      this.selectedPeopleList = record.selectedPeopleList;
    },
    async getRecordById(id) {
      const res = await this.api.getRecordById(id);
      console.log(res, "点单详情数据");
      this.taskStatus = res.data.taskStatus;
      this.evaluate = res.data.evaluate;
      this.gridId = res.data.gridId;
      this.recordInfo = res.data;
      switch (this.taskStatus) {
        case "0": //待审批
          break;
        case "1": //待接单
          this.active = 1;
          break;
        case "2": //进行中
          this.active = 2;
          break;
        case "3": //已完成
          this.active = 3;
          break;
        case "4": //已评价
          this.active = 4;
          break;
        case "5": //已取消
          break;
        default:
          this.active = 0;
      }
    },
    // 取消派单
    async cancelDispatch() {
      const obj = {
        id: this.id,
        volunteerId: "",
        volunteerPhone: "",
        taskStatus: "1",
      };
      this.saveType = true;
      try {
        const res = await api.auditRecord(obj);
        console.log(res);
        if (res.status === 200) {
          this.$message.success("取消派单成功");
          this.saveType = false;
          this.closeModal();
        } else {
          this.saveType = false;
          this.$message.error("取消派单失败");
        }
      } catch (err) {
        this.saveType = false;
        console.log(err);
      }
    },
    // 取消点单
    async cancelOrder() {
      const obj = {
        id: this.id,
        volunteerId: "",
        volunteerPhone: "",
        taskStatus: "5",
      };
      this.saveType = true;
      try {
        const res = await api.auditRecord(obj);
        console.log(res);
        if (res.status === 200) {
          this.$message.success("取消点单成功");
          this.saveType = false;
          this.closeModal();
        } else {
          this.saveType = false;
          this.$message.error("取消点单失败");
        }
      } catch (err) {
        this.saveType = false;
        console.log(err);
      }
    },
    // 通过审核设置积分
    pass() {
      this.visible1 = true;
    },
    // 设置积分
    async setScore() {
      console.log(this.integralScore, "471");
      const obj = {
        taskStatus: "1",
        id: this.id,
        integralScore: this.integralScore,
        gridId: this.gridId,
      };
      this.saveType = true;
      const res = await api.auditRecord(obj);
      console.log(res, "526");
      if (res.status === 200) {
        this.$message.success("审核成功");
        this.visible1 = false;
        this.saveType = false;
        this.closeModal();
      } else {
        this.saveType = false;
        this.$message.success("审核失败");
      }
    },
    // 审核不通过 填写拒绝理由
    refuse() {
      this.visible2 = true;
    },
    // 审核不通过 填写拒绝理由
    async setRefusalReason() {
      console.log(this.refuseReason, "471");
      const obj = {
        taskStatus: "6",
        id: this.id,
        refuseReason: this.refuseReason,
      };
      this.saveType = true;
      const res = await api.auditRecord(obj);
      if (res.status === 200) {
        this.$message.success("操作成功");
        this.visible2 = false;
        this.saveType = false;
        this.closeModal();
      } else {
        this.saveType = false;
        this.$message.success("操作失败");
      }
    },
    // 关闭弹窗
    closeModal() {
      this.visible = false;
      this.$emit("refsh");
    },
    closeModal1() {
      this.visible1 = false;
      this.integralScore = "";
    },
    closeModal2() {
      this.visible2 = false;
      this.refuseReason = "";
    },
  },
};
</script>
<style lang='less' scoped>
.rate {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
/deep/.ant-rate {
  font-size: 0.2rem !important;
}
/deep/.el-dialog {
  border-radius: 20px !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
/deep/.el-dialog__title {
  color: #2d3138;
}
/deep/.ant-descriptions-view {
  padding-left: 1%;
}
/deep/.ant-descriptions-title {
  font-size: 0.08rem;
  border-left: 4px solid #2a5cff;
  padding-left: 0.5%;
  line-height: 100%;
}

/deep/.ant-descriptions-item-label {
  color: #7d8da6 !important;
  padding-left: 4px;
}

/deep/.ant-descriptions-item-content {
  color: #2d3138;
}
/deep/.el-steps {
  padding: 0 5% !important;
  margin-top: 40px;
}
/deep/.el-step__head.is-process {
  color: #c6d7e1;
}
/deep/.el-step__head.is-wait {
  color: #c6d7e1;
}
/deep/.el-step__title.is-wait {
  color: #7d8da6;
}
/deep/.el-step__title.is-process {
  color: #7d8da6;
}
/deep/.iconfont {
  font-size: 0.18rem !important;
}
/deep/.el-input-number {
  width: 100%;
}
/deep/.el-input-number .el-input__inner {
  text-align: left;
}
</style>